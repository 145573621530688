import React from "react";

import { Dashboard } from "../components/dashboard";
import Button from "../components/button";

function Content() {
  return (
    <>
      <div className="banner">
        <h1 className="text-banner">診所公告</h1>
      </div>

      <Button />
      <Dashboard />
    </>
  );
}

export default Content;
