import { Container } from "react-bootstrap";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import Button from "../components/button";
import TimeTable from "../components/timetable";

function Schedule() {
  return (
    <>
      <div className="banner">
        <h1 className="text-banner">門診時間</h1>
      </div>
      <Button />
      <Container className="">
        <div className="jumbotron text-mid">
          <MDBRow className="row-cols-1 row-cols-lg-2">
            <MDBCol className="d-flex">
              <div className="bg-opacity-75 bg-white d-flex">
                <TimeTable fluid />
              </div>
            </MDBCol>
            <MDBCol>
              <div className="jumbotron text-mid">
                <p>在每一節門診時間結束前20分鐘停止掛號。</p>
                <p>
                  視力檢查需要提早在門診結束時間
                  <span className="text-highlight">20分鐘</span>
                  以前到達，如果需要散瞳或檢查眼底則需要
                  <span className="text-highlight">1個小時</span>以前到達。
                </p>
                <p>
                  沈院長的門診時間(
                  <span className="text-highlight">紅色勾的時段</span>
                  )為星期一上午及下午，星期三上午，星期五全天；門診偶有異動請電洽診所。
                </p>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </Container>
    </>
  );
}

export default Schedule;
