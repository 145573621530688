import { Container, Row, Col, Image } from "react-bootstrap";

import CardsSection from "../components/cards";
import { FirstMessage } from "../components/dashboard";
import MapSection from "../components/map";
import Button from "../components/button";

import homeImage from "../images/new_clinic/clinic_front.jpeg";
import MapImage from "../images/mapicon.jpg";
import smalllogo from "../images/tip.jpg";

const location = {
  address: "新北市淡水區中正東路二段75號",
  lat: 25.1543327029596,
  lng: 121.45966102696498,
};

function Home() {
  return (
    <>
      <Container>
        <Row className="row-cols-1 row-cols-sm-2 align-items-center">
          <Col>
            <Image src={homeImage} alt="homepage" fluid />
          </Col>
          <Col>
            {/* <h1 className="text-title kai">永光眼科診所</h1>
            <h1 className="text-mid">EVERLIGHT EYE CLINIC</h1> */}
            <Button />
            <div className="bordertron square mt-3">
              <h2 className="text-left">最新消息</h2>
              <hr />
            </div>
            <FirstMessage />
          </Col>
        </Row>

        {/* <Container className="float">
          <h1 className="text-title">永光眼科診所</h1>
          <h1 className="text-mid">EVERLIGHT EYE CLINIC</h1>
        </Container> */}
      </Container>
      <Container>
        <div className="jumbotron">
          <h2 className="text-center">交通資訊</h2>
          <hr />
          <p className="text-mid text-center">新北市淡水區中正東路二段75號</p>
          <p className="text-mid text-center">捷運紅樹林站正對面</p>
          <p className="text-mid text-center">永光眼科診所電話:02-26292616</p>
          <Row className="align-items-center row-cols-1 row-cols-md-2">
            <Col>
              <MapSection location={location} zoomLevel={17} />
            </Col>
            <Col>
              <Image src={MapImage} alt="directions" fluid></Image>
            </Col>
          </Row>
        </div>
      </Container>
      <Container>
        <div className="jumbotron">
          <h2 className="text-center">診所資訊</h2>
          <hr />
          <p className="text-mid">
            台大醫師主治：治療一般眼科疾病，提供微創超音波白內障手術，兒童近視控制。永光眼科為
            <span className="text-highlight">新北市北區</span>
            進行白內障手術及兒童近視控制的主要診所。
          </p>
          <p className="text-mid">
            永光眼科原為淡水日聖眼科，由
            <span className="text-highlight">沈詩詠院長</span>
            於1998年9月18日成立，進行白內障手術已有二十多年及數千例的經驗。初設於淡水學府路5號，曾搬遷至捷運淡水總站正對面，現已遷移至
            <span className="text-highlight">新北市淡水區中正東路二段75號</span>
            (捷運紅樹林站正對面)。
          </p>
        </div>
      </Container>
      <CardsSection />
    </>
  );
}

export default Home;
