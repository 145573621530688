import { Container, Image, Row, Col } from "react-bootstrap";
import frontImage from "../images/new_clinic/clinic_front.jpeg";
import interiorImage from "../images/new_clinic/couch.jpeg";

import Button from "../components/button";

function About() {
  return (
    <>
      <div className="banner">
        <h1 className="text-banner">診所介紹</h1>
      </div>

      <Container>
        <div className="jumbotron">
          <Button />
          <Row className="row-cols-1 row-cols-lg-2">
            <Col className="d-flex flex-column">
              <h2>永光眼科診所（原淡水日聖眼科診所）</h2>
              <hr />
              <ul className="list-unstyled text-mid">
                <li>
                  <i className="fas fa-check-circle" />{" "}
                  沈詩詠院長於民國87.9（1998.9）設立
                </li>
                <li>
                  <i className="fas fa-check-circle" /> 進行
                  <a href="/cataract">
                    <span className="text-highlight">白內障手術</span>
                  </a>
                  已有二十多年及數千例的經驗
                </li>
                <li>
                  <i className="fas fa-check-circle" /> 為
                  <span className="text-highlight">新北市北區</span>
                  進行白內障手術及兒童近視控制的主要診所
                </li>
                <li>
                  <i className="fas fa-check-circle" />{" "}
                  初設於淡水學府路5號，曾搬遷至捷運淡水總站正對面，現已遷移至新北市淡水區中正東路二段75號(捷運紅樹林站正對面)
                </li>
                <li>
                  <i className="fas fa-check-circle" /> 診所安裝
                  <a href="https://www.hitachiaircon.com/tw/ranges/commercial-iaq-systems/heat-energy-recovery-ventilator">
                    <span className="text-highlight">日立全熱交換器</span>
                  </a>
                  ，可過濾PM 2.5以及排出二氧化碳。
                </li>
                <li>
                  <i className="fas fa-check-circle" /> 診所備有歐洲進口
                  <a href="https://www.hdac.com.tw/product-Otolift-Modul-Air-Smart.html">
                    <span className="text-highlight">泓電樓梯升降椅</span>
                  </a>
                  以服務行動不便的患者
                </li>
              </ul>
            </Col>
            <Col>
              <Image src={frontImage} fluid></Image>
            </Col>
          </Row>
        </div>
      </Container>
      <Container className="">
        <div className="jumbotron">
          <Row className="align-items-center row-cols-1 row-cols-lg-2">
            <Col>
              <Image src={interiorImage} fluid />
            </Col>
            <Col className="d-flex flex-column">
              <h2>垂直整合策略聯盟之醫學中心</h2>
              <hr></hr>
              <ul className="list-unstyled text-mid">
                <li>
                  <i className="fas fa-clinic-medical"></i>{" "}
                  <a href="https://www.ntuh.gov.tw/RMC/Fpage.action?muid=5084&fid=5634">
                    <span className="text-highlight">臺大醫院星月計畫 </span>
                  </a>
                  / 國立臺灣大學醫學院附設醫院
                </li>
                <li>
                  <i className="fas fa-clinic-medical"></i>{" "}
                  <a href="https://www.mmh.org.tw/news_view.php?id=4612">
                    <span className="text-highlight">
                      馬偕共好醫療照護合作團隊{" "}
                    </span>
                  </a>
                  / 台灣基督長老教會馬偕醫療財團法人馬偕紀念醫院
                </li>
                {/* <li>
                  <i className="fas fa-clinic-medical"></i>{" "}
                  <a href="https://www.mmh.org.tw/news_view.php?id=4612">
                    <span className="text-highlight">
                      馬偕共好醫療照護合作團隊{" "}
                    </span>
                  </a>
                  / 台灣基督長老教會馬偕醫療財團法人馬偕兒童醫院
                </li>
                <li>
                  <i className="fas fa-clinic-medical"></i>{" "}
                  <a href="https://www.mmh.org.tw/news_view.php?id=4612">
                    <span className="text-highlight">
                      馬偕共好醫療照護合作團隊{" "}
                    </span>
                  </a>
                  / 台灣基督長老教會馬偕醫療財團法人淡水馬偕紀念醫院
                </li> */}
                <li>
                  <i className="fas fa-clinic-medical"></i>{" "}
                  <a href="https://www.skh.org.tw/skh/index.html">
                    <span className="text-highlight">
                      新光醫院共好醫療合作團隊{" "}
                    </span>
                  </a>
                  / 新光醫療財團法人新光吳火獅紀念醫院
                </li>
                <li>
                  <i className="fas fa-clinic-medical"></i>{" "}
                  <a href="https://wd.vghtpe.gov.tw/fm/Fpage.action?muid=10150&fid=9779">
                    <span className="text-highlight">
                      臺北榮總與社區診所雙向轉診團隊{" "}
                    </span>
                  </a>
                  / 臺北榮民總醫院
                </li>
                <li>
                  <i className="fas fa-clinic-medical"></i>{" "}
                  <a href="https://www.nhi.gov.tw/onlinequery/familydrsearch.aspx?menu=20&menu_id=926&webdata_id=3661&wd_id=929&querytype=2&city=&area=&hname=&hid=&cname=&cid=&h1name=&h1id=&page=126">
                    <span className="text-highlight">
                      淡水好鄰居社區醫療群會員
                    </span>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default About;
