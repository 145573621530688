import React from "react";
import Blogboard from "../components/blogboard";
import Button from "../components/button";

const Content = () => {
  return (
    <>
      <div className="banner">
        <h1 className="text-banner">保健知識</h1>
      </div>

      <Button />
      <Blogboard />
    </>
  );
};

export default Content;
