import React from "react";
import "./button.css";

const ScheduleButton = () => {
  return (
    <div className="text-center bordertron square mt-3">
      <a href="https://lin.ee/WQN1zBj">
        <button className="click-button">
          <h2 style={{ color: "white" }}>預約掛號</h2>
        </button>
      </a>
    </div>
  );
};

export default ScheduleButton;
